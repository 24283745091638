import maplibregl from 'maplibre-gl';
import { DatasetDefinition, GeoJsonDataSource, Renderer, Coordinate } from './maptypes';

let nextImageIndex = 0;

export const addDataset = (map: maplibregl.Map, dataset: DatasetDefinition) => {
  // different types of renderers are handled by different methods.
  var type = dataset.renderer.type;
  var result = false;
  if (type === 'symbol') {
    result = _addPointDataset(map, dataset);
  } else if (type === 'line') {
    result = _addLineDataset(map, dataset);
  } else if (type === 'circle') {
    result = _addCircleDataset(map, dataset);
    // } else if (type === 'fill') {
    //     result = _addPolygonDataset(map, dataset);
  } else {
    console.log('Dataset contains an unknonw renderer type: ' + type);
  }

  return result;
};

export const updateDataset = (map: maplibregl.Map, dataset: DatasetDefinition): boolean => {
  const sourceName = _getSourceId(dataset);
  if (map.getSource(sourceName)) {
    //Dataset already exists, we just update the data
    (map.getSource(sourceName) as maplibregl.GeoJSONSource).setData(dataset.data);
    return true;
  }

  return addDataset(map, dataset);
};

export const zoomToDataset = (map: maplibregl.Map, dsObj: DatasetDefinition) => {
  const data = dsObj.data;

  // do nothing if the 'data' property contains a URL string (to a remote GeoJSON document).
  if (typeof data === 'object') {
    var ext = _getGeoJsonExtent(data);
    if (ext) {
      // enlarge ext by 10%, then zoom the map to the enlarged extent.
      map.fitBounds(_scaleExtentBy(ext, 0.1));
    }
  }
};

export const removeDataset = (map: maplibregl.Map, dataset: DatasetDefinition): boolean => {
  let removed = false;
  const layerId = _getLayerId(dataset);
  const sourceId = _getSourceId(dataset);

  if (map.getLayer(layerId)) {
    map.removeLayer(layerId);
    removed = true;
  }

  if (map.getSource(sourceId)) {
    map.removeSource(sourceId);
    removed = true;
  }
  return removed;
};

const _getLayerId = (dsObj: DatasetDefinition): string => {
  return dsObj.name + '-layer';
};

const _getSourceId = (dsObj: DatasetDefinition): string => {
  return dsObj.name + '-src';
};

const _getSource = (dsObj: DatasetDefinition): maplibregl.GeoJSONSourceRaw => {
  return {
    type: 'geojson',
    data: dsObj.data,
  };
};

const _addPointDataset = (map: maplibregl.Map, dsObj: DatasetDefinition): boolean => {
  const renderer = dsObj.renderer;
  // an iconUrl can also be a simple sprite/image id referencing a particular sprite that's part of the current
  // map style.
  var isIconId = typeof renderer.iconId !== 'undefined';
  if (isIconId) {
    return _addPointLayerHelper(map, dsObj, undefined, renderer.iconId!);
  } else {
    // load image based on iconUrl.
    map.loadImage(renderer.iconUrl!, function (error: Error, image: HTMLImageElement) {
      if (error) {
        throw error;
      }

      if (!image) {
        throw 'Unable to load image.';
      }
      return _addPointLayerHelper(map, dsObj, image, null);
    });
  }
  return true;
};

const _addPointLayerHelper = (
  map: maplibregl.Map,
  dsObj: DatasetDefinition,
  image: HTMLImageElement | undefined,
  iconId: string | null
): boolean => {
  const renderer = dsObj.renderer;
  const layerId = _getLayerId(dsObj);
  const sourceId = _getSourceId(dsObj);

  // add the customer icon image to the map
  let imageId: string;
  if (image) {
    imageId = 'image-' + nextImageIndex;
    nextImageIndex++;
    map.addImage(imageId, image);
  } else {
    imageId = iconId!;
  }

  // add the new dataset's data as a source to the map.
  map.addSource(sourceId, _getSource(dsObj));

  // create and add the new dataset to the map as a new layer.
  const infoProvider = renderer.popup;

  map.addLayer({
    id: layerId,
    type: 'symbol',
    source: sourceId,
    layout: _createSymbolLayout(renderer, imageId),
    paint: {
      'icon-color': '#000000',
      'icon-halo-color': '#ffffff',
      'icon-halo-width': 3,
      'text-color': '#000000',
      'text-halo-color': ['rgba', 255, 255, 255, 0.4],
      'text-halo-width': 2,
    },
  });

  const clickListener = function (e: maplibregl.MapMouseEvent & { features?: maplibregl.MapboxGeoJSONFeature[] }) {
    // show a pop-up
    var coordinates = e.features![0].geometry.coordinates.slice();
    var description = buildPopupDescription(infoProvider, e.features![0]);

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    if (description && description !== '') {
      new maplibregl.Popup({ anchor: 'left' }).setLngLat(coordinates).setHTML(description).addTo(map);
    }
  };

  //Add event to show popup on click
  map.on('click', layerId, clickListener);

  _setupCursorBehavior(map, layerId);

  if (renderer.zoomToData) {
    zoomToDataset(map, dsObj);
  }
  return true;
};

const METER_TO_PIXEL_RATIOS: number[] = [
  0.019, //latitude == 0
  0.018, //latitude == +-20
  0.014, //latitude == +-40
  0.009, //latitude == +-60
  0.003, //latitude == +-80
];

const calculateRadiusInPixels = (meters: number, latitude: number): number => {
  const absLatitude = Math.abs(latitude);
  let ratio = METER_TO_PIXEL_RATIOS[0];
  if (absLatitude >= 80) {
    ratio = METER_TO_PIXEL_RATIOS[4];
  } else if (absLatitude >= 60) {
    ratio = METER_TO_PIXEL_RATIOS[3];
  } else if (absLatitude >= 40) {
    ratio = METER_TO_PIXEL_RATIOS[2];
  } else if (absLatitude >= 20) {
    ratio = METER_TO_PIXEL_RATIOS[1];
  }

  if (!meters) {
    meters = 100;
  }

  return meters / ratio / Math.cos((latitude * Math.PI) / 180);
};

const getLatitudeFromSource = (source: GeoJsonDataSource): number => {
  let c = source.features[0].geometry.coordinates as Coordinate; //Get first feature's coordinates
  return c[1]; //second value (latitude).
};

const _addCircleDataset = (map: maplibregl.Map, dsObj: DatasetDefinition): boolean => {
  const renderer = dsObj.renderer;
  const layerId = _getLayerId(dsObj);
  const sourceId = _getSourceId(dsObj);

  // add the new dataset's data as a source to the map.
  map.addSource(sourceId, _getSource(dsObj));

  // create and add the new dataset to the map as a new layer.
  const infoProvider = renderer.popup;

  const latitude = getLatitudeFromSource(dsObj.data);
  const radiusInPixels = calculateRadiusInPixels(renderer.circleRadius!, latitude);

  map.addLayer({
    id: layerId,
    type: 'circle',
    source: sourceId,
    layout: {},
    paint: {
      'circle-radius': {
        stops: [
          [0, 0],
          [22, radiusInPixels],
        ],
        base: 2,
      },
      // 'circle-radius': [
      //   "interpolate",
      //   ["exponential", 2],
      //   ["zoom"],
      //   0,
      //   0,
      //   22,
      //   radiusInPixels
      //   ],
      'circle-color': renderer.circleColor,
      'circle-opacity': renderer.circleOpacity,
    },
  });

  const clickListener = function (e: maplibregl.MapMouseEvent & { features?: maplibregl.MapboxGeoJSONFeature[] }) {
    // show a pop-up
    var coordinates = e.features![0].geometry.coordinates.slice();
    var description = buildPopupDescription(infoProvider, e.features![0]);

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    if (description && description !== '') {
      new maplibregl.Popup({ anchor: 'left' }).setLngLat(coordinates).setHTML(description).addTo(map);
    }
  };

  //Add event to show popup on click
  map.on('click', layerId, clickListener);

  _setupCursorBehavior(map, layerId);

  if (renderer.zoomToData) {
    zoomToDataset(map, dsObj);
  }
  return true;
};

const __isValidHex = (hex: string) => /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex);

const __getChunksFromString = (st: string, chunkSize: number) => {
  return st.match(new RegExp(`.{${chunkSize}}`, 'g'));
};

const __convertHexUnitTo256 = (hexStr: string) => {
  return parseInt(hexStr.repeat(2 / hexStr.length), 16);
};

const __getAlphafloat = (a: number, alpha: number) => {
  if (typeof a !== 'undefined') {
    return a / 255;
  }
  if (typeof alpha !== 'number' || alpha < 0 || alpha > 1) {
    return 1;
  }
  return alpha;
};

export const getRGBA = (hex: string): number[] => {
  if (!__isValidHex(hex)) {
    throw new Error('Invalid HEX');
  }
  const chunkSize = Math.floor((hex.length - 1) / 3);
  const hexArr = __getChunksFromString(hex.slice(1), chunkSize);
  return hexArr!.map(__convertHexUnitTo256);
};

const __hexToRGBA = (hex: string, alpha: number) => {
  const [r, g, b, a] = getRGBA(hex);
  return `rgba(${r}, ${g}, ${b}, ${__getAlphafloat(a, alpha)})`;
};

const _createSymbolLayout = (renderer: Renderer, imageId: string): maplibregl.SymbolLayout => {
  const iconScale = typeof renderer.iconScale === 'undefined' ? 1 : renderer.iconScale;
  const anchor: maplibregl.Anchor = renderer.iconAnchor || 'center'; // default icon anchor.
  const hasTextField = typeof renderer.textField !== 'undefined';
  const textIgnorePlacement = !!renderer.textIgnorePlacement;
  const textAllowOverlap = !!renderer.textAllowOverlap;

  const layout: Partial<maplibregl.SymbolLayout> = {
    'icon-image': imageId,
    'icon-size': iconScale,
    'icon-anchor': anchor,
    'icon-allow-overlap': true,
    'icon-rotate': ['get', 'rotation'],
  };

  if (hasTextField) {
    const fontLayout: Partial<maplibregl.SymbolLayout> = {
      'text-field': ['get', renderer.textField],
      'text-font': renderer.textFont, // default 'Noto Sans Regular'
      'text-ignore-placement': textIgnorePlacement,
      'text-allow-overlap': textAllowOverlap,
    };
    if (typeof renderer.textAutoPlacement === undefined || renderer.textAutoPlacement) {
      fontLayout['text-variable-anchor'] = ['left', 'top', 'bottom', 'right'];
      fontLayout['text-radial-offset'] = 0.5;
      //fontLayout['text-justify'] = 'auto';
    } else if (renderer.textOffset) {
      fontLayout['text-offset'] = renderer.textOffset;
    }

    Object.assign(layout, fontLayout);
  }

  return layout;
};

const _addLineDataset = (map: maplibregl.Map, dsObj: DatasetDefinition): boolean => {
  const renderer = dsObj.renderer;
  const layerId = _getLayerId(dsObj);
  const sourceId = _getSourceId(dsObj);

  // add the new dataset's data as a source to the map.
  const lineSource = _getSource(dsObj);
  lineSource.lineMetrics = true; //For line gradient interpolation this is needed
  map.addSource(sourceId, lineSource);

  // create and add the new dataset to the map as a new layer.
  var lineColor = renderer.lineColor || '#880';
  var hexLineColor = typeof renderer.lineColor === 'string' ? renderer.lineColor : '#088';
  var lineWidth = renderer.lineWidth || 2;
  var lineJoin = renderer.lineJoin || 'round';
  var lineCap = renderer.lineCap || 'round';
  var lineOpacity = typeof renderer.lineOpacity === 'undefined' ? 1 : renderer.lineOpacity;
  var infoProvider = renderer.popup;

  const lineLayer: Partial<maplibregl.LineLayer> = {
    id: layerId,
    type: 'line',
    source: sourceId,
    layout: {
      'line-join': lineJoin,
      'line-cap': lineCap,
    },
    paint: {
      'line-color': lineColor,
      'line-width': lineWidth,
    },
  };

  if (lineOpacity === 'use-gradient-opacity') {
    lineLayer.paint!['line-gradient'] = [
      'interpolate',
      ['linear'],
      ['line-progress'],
      0,
      __hexToRGBA(hexLineColor, 0.1),
      0.5,
      __hexToRGBA(hexLineColor, 0.3),
      1,
      __hexToRGBA(hexLineColor, 0.8),
    ];
    // 'line-gradient': [
    //     'interpolate',
    //     ['linear'],
    //     ['line-progress'],
    //     0,
    //     'blue',
    //     0.1,
    //     'royalblue',
    //     0.3,
    //     'cyan',
    //     0.5,
    //     'lime',
    //     0.7,
    //     'yellow',
    //     1,
    //     'red'
    // ]
  } else {
    lineLayer.paint!['line-opacity'] = lineOpacity;
  }

  map.addLayer(lineLayer as maplibregl.LineLayer);

  const clickListener = function (e: maplibregl.MapMouseEvent & { features?: maplibregl.MapboxGeoJSONFeature[] }) {
    var coordinates = e.lngLat;
    var description = buildPopupDescription(infoProvider, e.features![0]);

    if (description && description !== '') {
      new maplibregl.Popup().setLngLat(coordinates).setHTML(description).addTo(map);
    }
  };

  //Add event to show popup on click
  map.on('click', layerId, clickListener);

  _setupCursorBehavior(map, layerId);

  if (renderer.zoomToData) {
    zoomToDataset(map, dsObj);
  }
  return true;
};

/**
 * Builds the descriptive contents of a Maplibre pop up, typically in HTML.
 *
 * @param  {Object} infoProvider Can be either a function (that returns the fully constructed HTML contents) or
 *                               data contents.
 * @param  {Object} feature      The geographic feature the pop up is for.
 */
export const buildPopupDescription = (
  infoProvider: Function | string[] | string | undefined,
  feature: maplibregl.MapboxGeoJSONFeature
) => {
  if (infoProvider && typeof infoProvider === 'function') {
    return infoProvider(feature);
  } else {
    let infoProperties: string[] = [];
    let description = '';
    if (infoProvider && Array.isArray(infoProvider)) {
      infoProperties = infoProvider;
    } else if (infoProvider && typeof infoProvider === 'string') {
      infoProperties = [infoProvider];
    }
    description = infoProperties
      .filter((key) => feature.properties.hasOwnProperty(key))
      .map((entry) => entry + ': ' + feature.properties[entry])
      .join('<br/>');
    return description;
  }
};

////////////////////////////////////////////////////////////////////////////////////////////////////
////////// MAP UTILITY FUNCTIONS
////////////////////////////////////////////////////////////////////////////////////////////////////

const _setupCursorBehavior = (map: maplibregl.Map, layerId: string) => {
  const mouseenterListener = function () {
    map.getCanvas().style.cursor = 'pointer';
  };

  // Change the cursor to a pointer when the mouse is over the places layer.
  map.on('mouseenter', layerId, mouseenterListener);
  // this.saveEvent('mouseenter', mouseenterListener);

  const mouseleaveListener = function () {
    map.getCanvas().style.cursor = '';
  };

  // Change it back to a pointer when it leaves.
  map.on('mouseleave', layerId, mouseleaveListener);
  // this.saveEvent('mouseleave', mouseleaveListener);
};

const _getGeoJsonExtent = (json: GeoJsonDataSource) => {
  var ext: number[] = [];
  var foundValidExtent = false;

  var features = json.features;
  if (!features || features.length === 0) {
    return null;
  }

  for (var i = 0; i < features.length; i++) {
    var f = features[i];

    var fExt = _getFeatureExtent(f);
    if (!fExt) {
      continue;
    }

    if (!foundValidExtent) {
      ext = fExt;
      foundValidExtent = true;
    } else {
      _extend(ext, fExt);
    }
  }

  return ext;
};

const _getFeatureExtent = (f: maplibregl.MapboxGeoJSONFeature) => {
  var geom = f.geometry;
  if (!geom) {
    return null;
  }

  var type = geom.type;
  var coords = geom.coordinates;

  switch (type.toUpperCase()) {
    case 'POINT':
      return _getPointExtent(coords);
    case 'LINESTRING':
      return _getLineStringExtent(coords);
    case 'POLYGON':
      return _getPolygonExtent(coords);
    case 'MULTIPOINT':
      return _getMultiPointExtent(coords);
    case 'MULTILINESTRING':
      return _getMultiLineStringExtent(coords);
    case 'MULTIPOLYGON':
      return _getMultiPolygonExtent(coords);
    case 'GEOMETRYCOLLECTION':
    default:
      return null;
  }
};

const _extend = (ext1: number[], ext2: number[]) => {
  if (!ext1 || !ext2) {
    return;
  }

  ext1[0] = ext1[0] > ext2[0] ? ext2[0] : ext1[0]; // xmin
  ext1[1] = ext1[1] > ext2[1] ? ext2[1] : ext1[1]; // ymin
  ext1[2] = ext1[2] < ext2[2] ? ext2[2] : ext1[2]; // xmax
  ext1[3] = ext1[3] < ext2[3] ? ext2[3] : ext1[3]; // ymax
};

const _scaleExtentBy = (ext: number[], scalingFactor: number): [number, number, number, number] => {
  if (!ext || scalingFactor === 1 || scalingFactor === 0) {
    return ext as [number, number, number, number];
  }

  var width = ext[2] - ext[0];
  var height = ext[3] - ext[1];

  var deltaW = (width * scalingFactor) / 2;
  var deltaH = (height * scalingFactor) / 2;

  ext[0] = ext[0] - deltaW;
  ext[1] = ext[1] - deltaH;
  ext[2] = ext[2] + deltaW;
  ext[3] = ext[3] + deltaH;

  return ext as [number, number, number, number];
};

const _getPointExtent = (coords: number[]) => {
  if (!coords || coords.length === 0) {
    return null;
  }

  return [coords[0], coords[1], coords[0], coords[1]];
};

// Ring = a single LineString, or a single closed ring in a Polygon.
const _getRingExtent = (coords: number[][]) => {
  if (!coords || coords.length === 0) {
    return null;
  }

  var pair = coords[0];
  var xmin = pair[0],
    ymin = pair[1],
    xmax = pair[0],
    ymax = pair[1];

  for (var i = 1; i < coords.length; i++) {
    pair = coords[i];
    xmin = xmin > pair[0] ? pair[0] : xmin;
    ymin = ymin > pair[1] ? pair[1] : ymin;
    xmax = xmax < pair[0] ? pair[0] : xmax;
    ymax = ymax < pair[1] ? pair[1] : ymax;
  }

  return [xmin, ymin, xmax, ymax];
};

const _getLineStringExtent = (coords: number[][]) => {
  return _getRingExtent(coords);
};

// A Polygon always has one (outer) ring,
// plus zero or more interior rings (holes).
const _getPolygonExtent = (coords: number[][][]) => {
  var xmin = 0,
    ymin = 0,
    xmax = 0,
    ymax = 0;
  var foundValidExtent = false;

  for (var i = 0; i < coords.length; i++) {
    var ring = coords[i];

    var ringExt = _getRingExtent(ring);
    if (!ringExt) {
      continue;
    }

    if (!foundValidExtent) {
      [xmin, ymin, xmax, ymax] = ringExt;
      foundValidExtent = true;
    } else {
      xmin = xmin > ringExt[0] ? ringExt[0] : xmin;
      ymin = ymin > ringExt[1] ? ringExt[1] : ymin;
      xmax = xmax < ringExt[0] ? ringExt[0] : xmax;
      ymax = ymax < ringExt[1] ? ringExt[1] : ymax;
    }
  }

  return foundValidExtent ? [xmin, ymin, xmax, ymax] : null;
};

const _getMultiPointExtent = (coords: number[][]) => {
  //multipoint geometry's coordinates are organized the same as a ring.
  return _getRingExtent(coords);
};

const _getMultiLineStringExtent = (coords: number[][][]) => {
  //structurally speaking, a MultiLineString's coordinates are organized the same as a Polygon.
  return _getPolygonExtent(coords);
};

const _getMultiPolygonExtent = (coords: number[][][][]) => {
  var xmin = 0,
    ymin = 0,
    xmax = 0,
    ymax = 0;

  var foundValidExtent = false;

  for (var i = 0; i < coords.length; i++) {
    var polygon = coords[i];

    var polygonExt = _getPolygonExtent(polygon);
    if (!polygonExt) {
      continue;
    }

    if (!foundValidExtent) {
      [xmin, ymin, xmax, ymax] = polygonExt;
      foundValidExtent = true;
    } else {
      xmin = xmin > polygonExt[0] ? polygonExt[0] : xmin;
      ymin = ymin > polygonExt[1] ? polygonExt[1] : ymin;
      xmax = xmax < polygonExt[0] ? polygonExt[0] : xmax;
      ymax = ymax < polygonExt[1] ? polygonExt[1] : ymax;
    }
  }

  return foundValidExtent ? [xmin, ymin, xmax, ymax] : null;
};
