import React, { useRef, useEffect, useState } from 'react';
import { PanelProps, TIME_SERIES_TIME_FIELD_NAME } from '@grafana/data';
import { SimpleOptions, BoatData, BoatPosition, RaceBoundariesMessage, BoundaryData, GateData, MarkData } from 'types';
import { css, cx } from 'emotion';
import { stylesFactory } from '@grafana/ui';
import './styles.css';
import 'maplibre-gl/dist/maplibre-gl.css';
import maplibregl from 'maplibre-gl';
import { MapStyles, IconRotation } from 'MapStyles';
import {
  DatasetDefinition,
  GeoJsonFeature,
  GeoJsonPointFeature,
  GeoJsonLineFeature,
  GeoJsonDataSource,
  GeoJsonLineDataSource,
  GeoJsonPointDataSource,
  Coordinate,
  GeoJsonGateProperties,
  GeoJsonLineGeometry,
} from 'maptypes';
import { updateDataset, removeDataset, zoomToDataset } from './MapHelper';
//import { samples } from './boundaries';
// import whiteRocket from 'rocket-white.svg';
// import blackRocket from 'rocket-black.svg';

interface Props extends PanelProps<SimpleOptions> {}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(0);
  const [lat, setLat] = useState(0);
  const [zoom, setZoom] = useState(0);
  const [icon, setIcon] = useState('rocket_11');
  let [usedWidth, setUsedWidth] = useState(width);
  let [usedHeight, setUsedHeight] = useState(height);
  let [usedTrailSize, setUsedTrailSize] = useState(options.trailSize);
  let [usedIdName, setUsedIdName] = useState(options.idName);
  let [usedLatitudeName, setUsedLatitudeName] = useState(options.latitudeName);
  let [usedLongitudeName, setUsedLongitudeName] = useState(options.longitudeName);
  let [usedBoundariesName, setUsedBoundariesName] = useState(options.boundariesName);
  let [usedMultiplier, setUsedMultiplier] = useState(options.multiplier);
  let [displayedData, setDisplayedData] = useState<Partial<DatasetDefinition>>({});
  let [currentBoundaryData, setCurrentBoundaryData] = useState<Partial<BoundaryData>>({ t: 0, data: {} });
  let [currentGateData, setCurrentGateData] = useState<Partial<GateData>>({});
  let [currentMarkData, setCurrentMarkData] = useState<Partial<MarkData>>({ t: 0, data: {} });
  const [moveEventSet, setMoveEventSet] = useState(false);
  const [currentMapStyle, setCurrentMapStyle] = useState(options.mapStyle);

  const BOAT_POSITION_DATASET_NAME = 'sail-boat-locations';
  const BOAT_TRAILS_DATASET_NAME = 'sail-boat-trails';
  const RACE_BOUNDARIES_DATASET_NAME = 'race-boundaries';
  const RACE_GATES_DATASET_NAME = 'race-gates';
  const RACE_BUOYS_DATASET_NAME = 'race-buoys';

  //Boat colors based on their ID
  const COLORS: { [key: string]: string } = {
    AUS: '#00F',
    DEN: '#F00',
    ESP: '#A50',
    FRA: '#808',
    GBR: '#303',
    JPN: '#0F0',
    NZL: '#2FC',
    USA: '#F0F',
  };

  //Boat current position (marker)
  const BOAT_POSITIONS_DATASET: DatasetDefinition = {
    name: BOAT_POSITION_DATASET_NAME,
    data: {
      type: 'FeatureCollection',
      features: [],
    },

    renderer: {
      type: 'symbol',
      iconId: options.iconStyle,
      iconScale: 1.5,
      iconAnchor: 'center',
      textField: 'name',
      textFont: ['Noto Sans Regular'],
      textIgnorePlacement: true,
      textAllowOverlap: true,
      textOffset: [0, 1],
      textAutoPlacement: false,
    },
  };

  //Boats' past locations (trail)
  const BOAT_TRAILS_DATASET: DatasetDefinition = {
    name: BOAT_TRAILS_DATASET_NAME,
    data: {
      type: 'FeatureCollection',
      features: [],
    },

    // how to display the trailsData in their normal state
    renderer: {
      type: 'line',
      zoomToData: false, // zoom and pan the map to the extent of the dataset
      lineColor: ['get', 'color'],
      lineWidth: 3,
      lineOpacity: 0.5,
      //lineOpacity: 'use-gradient-opacity',
      popup: ['id', 'name'],
    },
  };

  //Race boundaries (delimiter lines)
  const RACE_BOUNDARIES_DATASET: DatasetDefinition = {
    name: RACE_BOUNDARIES_DATASET_NAME,
    data: {
      type: 'FeatureCollection',
      features: [],
    },

    renderer: {
      type: 'line',
      zoomToData: false, // zoom and pan the map to the extent of the dataset
      lineColor: ['get', 'stroke'],
      lineWidth: 1, //['get', 'draw'],
      lineOpacity: 1,
      popup: ['name', 'type'],
    },
  };

  //Race goals between buoys (lines)
  const RACE_GATES_DATASET: DatasetDefinition = {
    name: RACE_GATES_DATASET_NAME,
    data: {
      type: 'FeatureCollection',
      features: [],
    },

    renderer: {
      type: 'line',
      zoomToData: false, // zoom and pan the map to the extent of the dataset
      lineColor: '#5f5',
      lineWidth: 2,
      lineOpacity: 1,
      popup: ['id', 'name', 'type'],
    },
  };

  //Race buoys (points)
  const RACE_BUOYS_DATASET: DatasetDefinition = {
    name: RACE_BUOYS_DATASET_NAME,
    data: {
      type: 'FeatureCollection',
      features: [],
    },
    renderer: {
      type: 'circle',
      zoomToData: false, // zoom and pan the map to the extent of the dataset
      popup: ['id', 'name', 'type'],
      circleRadius: 45.72, //150ft or 45.72m
      circleColor: '#3a3',
      circleOpacity: 0.4,
    },
  };

  //Initialize once
  useEffect(() => {
    if (map.current) {
      return;
    } // initialize map only once
    // @ts-ignore: Object is possibly 'null'.
    map.current = new maplibregl.Map({
      container: mapContainer.current!,
      style: MapStyles[currentMapStyle],
      center: [lng, lat],
      zoom: zoom,
      preserveDrawingBuffer: false,
      transformRequest: (url, resourceType) => {
        // Supply the required header when requesting vector tiles from the eLocation server.

        if (
          resourceType === 'Tile' &&
          (url.startsWith('https://elocation.oracle.com/mapviewer/pvt') ||
            url.startsWith('https://elocation-stage.oracle.com/mapviewer/pvt') ||
            url.startsWith('https://maps.oracle.com/mapviewer/pvt'))
        ) {
          return {
            url: url,
            headers: { 'x-oracle-pvtile': 'OracleSpatial' },
            credentials: 'include',
          };
        } else {
          return {
            url: url,
          };
        }
      },
    });
    // @ts-ignore: Object is possibly 'null'.
    map.current.addControl(
      new maplibregl.NavigationControl({
        showCompass: false,
      })
    );
    // @ts-ignore: Object is possibly 'null'.
    map.current.dragRotate.disable();
  });

  //Add move event
  useEffect(() => {
    if (!map.current || moveEventSet) {
      return; // wait for map to initialize
    }
    // @ts-ignore: Object is possibly 'null'.
    map.current.on('move', () => {
      // @ts-ignore: Object is possibly 'null'.
      setLng(map.current.getCenter().lng.toFixed(4));
      // @ts-ignore: Object is possibly 'null'.
      setLat(map.current.getCenter().lat.toFixed(4));
      // @ts-ignore: Object is possibly 'null'.
      setZoom(map.current.getZoom().toFixed(2));
    });
    console.log('move event set!');
    setMoveEventSet(true);

    //Add rocket icons
    // let img1 = new Image(19, 19);
    // // @ts-ignore: Object is possibly 'null'.
    // img1.onload = () => map.current.addImage('rocket_black', img1);
    // img1.src = blackRocket;

    // let img2 = new Image(19, 19);
    // // @ts-ignore: Object is possibly 'null'.
    // img2.onload = () => map.current.addImage('rocket_white', img2);
    // img2.src = whiteRocket;
    // document.getElementById('oracle-maps-container')?.addEventListener('resize', function () {
    //   map.current.resize();
    // });
  }, [moveEventSet]);

  //Read Grafana's panel dimensions and resize MapLibre canvas accordingly
  useEffect(() => {
    if (!map.current) {
      return; //wait for map to initialize
    }
    if (usedWidth !== width || usedHeight !== height) {
      // @ts-ignore: Object is possibly 'null'.
      map.current.resize();
      setUsedWidth(width);
      setUsedHeight(height);
    }
  }, [usedWidth, width, usedHeight, height]);

  //Modify trail size on Panel settings change
  useEffect(() => {
    if (!map.current) {
      return; //wait for map to initialize
    }
    if (usedTrailSize !== options.trailSize) {
      setUsedTrailSize(options.trailSize);
    }
  }, [usedTrailSize, options.trailSize]);

  //Modify id/longitude/latitude columns on Panel settings change
  useEffect(() => {
    if (!map.current) {
      return; //wait for map to initialize
    }
    if (usedIdName !== options.idName) {
      setUsedIdName(options.idName);
    }
    if (usedLatitudeName !== options.latitudeName) {
      setUsedLatitudeName(options.latitudeName);
    }
    if (usedLongitudeName !== options.longitudeName) {
      setUsedLongitudeName(options.longitudeName);
    }
    if (usedBoundariesName !== options.boundariesName) {
      setUsedBoundariesName(options.boundariesName);
    }
    if (usedMultiplier !== options.multiplier) {
      setUsedMultiplier(options.multiplier);
    }
  }, [
    usedIdName,
    options.idName,
    options.latitudeName,
    options.longitudeName,
    options.multiplier,
    options.boundariesName,
    usedLatitudeName,
    usedLongitudeName,
    usedMultiplier,
    usedBoundariesName,
  ]);

  //Change map style on Panel settings change
  useEffect(() => {
    if (map.current === null) {
      return; //wait for map to initialize
    }
    if (currentMapStyle !== options.mapStyle) {
      // @ts-ignore: Object is possibly 'null'.
      map.current.setStyle(MapStyles[options.mapStyle]);
      setCurrentMapStyle(options.mapStyle);
    }
  }, [currentMapStyle, options.mapStyle]);

  //const MULTIPLIER = 0.0000001;//lon/lat values need to be divided by 10000000 (multiplied by 0.0000001).

  //Add longitude value to the boat ID at the specified time
  const addLongitude = (boats: Partial<BoatData>, id: string, t: number, v: number) => {
    let longitudeValue = v * usedMultiplier;
    if (boats[id] !== undefined) {
      const locations = boats[id] as Array<Partial<BoatPosition>>;
      const index = locations.findIndex((loc) => loc.t === t);
      if (index !== -1) {
        locations[index].lon = longitudeValue;
      } else {
        let partialLon: Partial<BoatPosition> = {
          t: t,
          lon: longitudeValue,
        };
        locations.push(partialLon);
      }
    } else {
      let partialLon: Partial<BoatPosition> = {
        t: t,
        lon: longitudeValue,
      };
      boats[id] = [];
      (boats[id] as Array<Partial<BoatPosition>>).push(partialLon);
    }
  };

  //Add latitude value to the boat ID at the specified time
  const addLatitude = (boats: Partial<BoatData>, id: string, t: number, v: number) => {
    let latitudeValue = v * usedMultiplier;
    if (boats[id]) {
      const locations = boats[id] as Array<Partial<BoatPosition>>;
      const index = locations!.findIndex((loc) => loc.t === t);
      if (index !== -1) {
        locations![index].lat = latitudeValue;
      } else {
        let partialLat: Partial<BoatPosition> = {
          t: t,
          lat: latitudeValue,
        };
        locations!.push(partialLat);
      }
    } else {
      let partialLat: Partial<BoatPosition> = {
        t: t,
        lat: latitudeValue,
      };
      boats[id] = [];
      (boats[id] as Array<Partial<BoatPosition>>).push(partialLat);
    }
  };

  //Get coordinates sorted by time
  const getCoordinates = (positions: BoatPosition[]): Coordinate[] => {
    const sortedPositions = positions.sort((a, b) => {
      return (a as BoatPosition).t - (b as BoatPosition).t;
    });
    const coordinates: Coordinate[] = [];
    sortedPositions.forEach((position) => {
      if (position.lon && position.lat) {
        coordinates.push([position.lon, position.lat]);
      }
    });
    return coordinates;
  };

  //Calculate the boat rotation angle using the last two locations
  const getCurrentRotation = (lastPoint: number[], beforeLastPoint: number[]): number => {
    const deltaX = lastPoint[0] - beforeLastPoint[0];
    const deltaY = lastPoint[1] - beforeLastPoint[1];
    let angle = Math.atan2(deltaY, deltaX);

    if (angle < 0) {
      angle = Math.abs(angle);
    } else {
      angle = 2 * Math.PI - angle;
    }

    return (angle * 180) / Math.PI;
  };

  //Get a color for the given boat ID or use the default
  const getColorForId = (id: string): string => {
    return COLORS[id] || '#027';
  };

  //Update all map datasets using the latest data
  const updatePoints = (boats: Partial<BoatData>, boundaryMessages: RaceBoundariesMessage[]): void => {
    if (boats) {
      let trailsDatasetDefinition = { ...BOAT_TRAILS_DATASET };
      let boatsDatasetDefinition = { ...BOAT_POSITIONS_DATASET };

      let trailsDataset = trailsDatasetDefinition.data;
      let boatsPosition = boatsDatasetDefinition.data;

      for (const [id, positions] of Object.entries(boats)) {
        let color: string = getColorForId(id);
        let coordinates: Coordinate[] = getCoordinates(positions!);

        // not ready yet?
        if (coordinates.length === 0) {
          continue;
        }

        if (coordinates.length > usedTrailSize) {
          coordinates = coordinates.slice(coordinates.length - usedTrailSize);
        }
        let lastPoint: number[] = coordinates[coordinates.length - 1];
        let beforeLastPoint: number[] = [0, 0];
        if (coordinates.length > 1) {
          beforeLastPoint = coordinates[coordinates.length - 2];
        }
        let rotation: number = IconRotation[options.iconStyle] + getCurrentRotation(lastPoint, beforeLastPoint);
        //console.log(id, coordinates);
        let trailFeature: GeoJsonFeature = {
          type: 'Feature',
          properties: {
            id: id,
            name: id,
            color: color,
          },
          geometry: {
            type: 'LineString',
            coordinates: coordinates,
          },
        };
        trailsDataset.features.push(trailFeature);

        let pointFeature: GeoJsonFeature = {
          type: 'Feature',
          properties: {
            id: id,
            name: id,
            color: color,
            rotation: rotation,
          },
          geometry: {
            type: 'Point',
            coordinates: [lastPoint[0], lastPoint[1]],
          },
        };
        boatsPosition.features.push(pointFeature);
      }

      if (icon !== options.iconStyle) {
        setIcon(options.iconStyle);
        removeDataset(map.current!, boatsDatasetDefinition);
      }

      updateDataset(map.current!, trailsDatasetDefinition);
      updateDataset(map.current!, boatsDatasetDefinition);
      setDisplayedData(boatsDatasetDefinition);
    }

    if (boundaryMessages && boundaryMessages.length > 0) {
      //Update boundaries if needed
      //Sort by time, in descending order (last message first)
      const sortedBoundaries = boundaryMessages.sort((a, b) => {
        return b.t - a.t;
      });

      let boundaryCount = 0;
      let boundariesUpdated = false;
      let marksUpdated = false;

      do {
        const boundaryMessage: RaceBoundariesMessage = sortedBoundaries[boundaryCount];
        const boundariesArray: GeoJsonDataSource[] = JSON.parse(boundaryMessage.data);
        for (let geoJsonCount = 0; geoJsonCount < boundariesArray.length; geoJsonCount++) {
          const boundaryGeoJson: GeoJsonDataSource = boundariesArray[geoJsonCount];
          if (isBoundaryGeoJson(boundaryGeoJson)) {
            if (shouldUpdateBoundaries(boundaryMessage.t)) {
              let updated = updateBoundaries(boundaryGeoJson as GeoJsonLineDataSource);
              if (updated) {
                boundariesUpdated = true;
                currentBoundaryData.t = boundaryMessage.t;
                setCurrentBoundaryData(currentBoundaryData);
              }
            } else {
              //Just ignore geoJson for being an old message (vs last saved data)
              continue;
            }
          } else if (isMarkGeoJson(boundaryGeoJson)) {
            if (shouldUpdateMarks(boundaryMessage.t)) {
              updateMarks(boundaryGeoJson as GeoJsonPointDataSource);
              marksUpdated = true;
              currentMarkData.t = boundaryMessage.t;
              setCurrentMarkData(currentMarkData);
            } else {
              //Just ignore geoJson for being an old message (vs last saved data)
              continue;
            }
          } else {
            //Should not reach this part
            console.log('Received an unknown type of GeoJSON document.');
          }
        }

        boundaryCount++;
      } while (boundaryCount < sortedBoundaries.length && !(boundariesUpdated && marksUpdated));

      if (boundariesUpdated) {
        let raceBoundariesDatasetDefinition = { ...RACE_BOUNDARIES_DATASET };
        raceBoundariesDatasetDefinition.data.features = getBoundaryFeatures();
        updateDataset(map.current!, raceBoundariesDatasetDefinition);
      }

      //When marks are updated, both goal and mark datasets are updated
      if (marksUpdated) {
        let raceGatesDatasetDefinition = { ...RACE_GATES_DATASET };
        raceGatesDatasetDefinition.data.features = getGateFeatures();
        updateDataset(map.current!, raceGatesDatasetDefinition);

        let raceBuoysDatasetDefinition = { ...RACE_BUOYS_DATASET };
        raceBuoysDatasetDefinition.data.features = getMarkFeatures();
        updateDataset(map.current!, raceBuoysDatasetDefinition);
      }
    }
  };

  const isBoundaryGeoJson = (geoJson: GeoJsonDataSource) => {
    if (geoJson.features[0].properties.type === 'Boundary') {
      return true;
    }
    return false;
  };

  const isMarkGeoJson = (geoJson: GeoJsonDataSource) => {
    if (geoJson.features[0].properties.type === 'Mark') {
      return true;
    }
    return false;
  };

  const shouldUpdateBoundaries = (t: number) => {
    //Compare input timestamp against last boundaries' updated timestamp
    if (!currentBoundaryData.t || t > currentBoundaryData.t) {
      return true;
    }
    return false;
  };

  const shouldUpdateMarks = (t: number) => {
    //Compare input timestamp against last marks' updated timestamp
    if (!currentMarkData.t || t > currentMarkData.t) {
      return true;
    }
    return false;
  };

  const updateBoundaries = (boundaryGeoJson: GeoJsonLineDataSource): boolean => {
    if (!currentBoundaryData.data) {
      currentBoundaryData.data = {};
    }

    let boundariesChanged = false;

    for (let featureCount = 0; featureCount < boundaryGeoJson.features.length; featureCount++) {
      const currentFeature = boundaryGeoJson.features[featureCount];
      if (currentFeature.properties.type === 'Boundary') {
        if (currentBoundaryData.data[currentFeature.properties.name]) {
          currentBoundaryData.data[currentFeature.properties.name].geometry = currentFeature.geometry;
        } else {
          currentBoundaryData.data[currentFeature.properties.name] = {
            properties: currentFeature.properties,
            geometry: currentFeature.geometry,
          };
        }
        boundariesChanged = true;
      } else if (currentFeature.properties.type === 'Gate') {
        if (currentGateData[currentFeature.properties.name]) {
          currentGateData[currentFeature.properties.name]!.geometry = currentFeature.geometry;
        } else {
          currentGateData[currentFeature.properties.name] = {
            properties: currentFeature.properties,
            geometry: currentFeature.geometry,
          };
        }
      }
    }

    setCurrentBoundaryData(currentBoundaryData);
    setCurrentGateData(currentGateData);

    return boundariesChanged;
  };

  const updateMarks = (markGeoJson: GeoJsonPointDataSource) => {
    if (!currentMarkData.data) {
      currentMarkData.data = {};
    }

    for (let featureCount = 0; featureCount < markGeoJson.features.length; featureCount++) {
      const currentFeature = markGeoJson.features[featureCount];
      if (currentFeature.properties.type === 'Mark') {
        if (currentMarkData.data[currentFeature.properties.sourceid]) {
          currentMarkData.data[currentFeature.properties.sourceid].geometry = currentFeature.geometry;
        } else {
          currentMarkData.data[currentFeature.properties.sourceid] = {
            properties: currentFeature.properties,
            geometry: currentFeature.geometry,
          };
        }
      }
    }

    setCurrentMarkData(currentMarkData);
  };

  const getBoundaryFeatures = (): GeoJsonLineFeature[] => {
    const features: GeoJsonLineFeature[] = [];
    for (const boundaryName in currentBoundaryData.data) {
      const feature = currentBoundaryData.data[boundaryName];
      features.push({
        type: 'Feature',
        ...feature, //Copy the feature's properties
      });
    }
    return features;
  };

  const getGateFeatures = (): GeoJsonLineFeature[] => {
    const features: GeoJsonLineFeature[] = [];
    for (const gateName in currentGateData) {
      const feature = currentGateData[gateName];
      if (feature) {
        const newFeature: GeoJsonLineFeature = {
          type: 'Feature',
          ...feature,
        };
        newFeature.geometry.coordinates = getUpdatedLineCoordinates(
          (newFeature.properties as GeoJsonGateProperties).sourceids,
          newFeature.geometry
        );
        features.push(newFeature);
      }
    }
    return features;
  };

  const getMarkFeatures = (): GeoJsonPointFeature[] => {
    const features: GeoJsonPointFeature[] = [];
    for (const markName in currentMarkData.data) {
      const feature = currentMarkData.data[markName];
      features.push({
        type: 'Feature',
        ...feature, //Copy the feature's properties
      });
    }
    return features;
  };

  const getUpdatedLineCoordinates = (
    sourceids: [string, string],
    currentGeometry: GeoJsonLineGeometry
  ): Coordinate[] => {
    //Lookup the two source Ids in the list of marks and build a line coordinates array.
    if (currentMarkData.data![sourceids[0]] && currentMarkData.data![sourceids[1]]) {
      return [
        currentMarkData.data![sourceids[0]].geometry.coordinates,
        currentMarkData.data![sourceids[1]].geometry.coordinates,
      ];
    } else {
      //If for some reason the marks do not exist, return the initial gate location
      console.log('Could not find sourceids, returning initial gate location.');
      return currentGeometry.coordinates;
    }
  };

  //If the map is loaded, read the data series and get the relevant location information
  // @ts-ignore: Object is possibly 'null'.
  if (map.current && map.current.loaded()) {
    const reLon = new RegExp(usedLongitudeName);
    const reLat = new RegExp(usedLatitudeName);
    const reBoundaries = new RegExp(usedBoundariesName);

    //Find longitude and latitude values
    const boats: Partial<BoatData> = {};
    const boundaries: RaceBoundariesMessage[] = [];

    let idxs_lon: Array<{ s: number; f: number; src: string | undefined }> = [];
    let idxs_lat: Array<{ s: number; f: number; src: string | undefined }> = [];
    let idxs_time: number[] = [];
    let idxs_boundaries: Array<{ s: number; f: number }> = [];

    // scan all series for time and lat/lon fields
    // TODO: utilize data.structureRev to optimize?
    for (let s = 0; s < data.series.length; s++) {
      for (let f = 0; f < data.series[s].fields.length; f++) {
        const field = data.series[s].fields[f];
        if (reLon.test(field.name)) {
          idxs_lon.push({ s, f, src: field.labels?.[usedIdName] || field.name.replace(reLon, (m, g1) => g1) });
        } else if (reLat.test(field.name)) {
          idxs_lat.push({ s, f, src: field.labels?.[usedIdName] || field.name.replace(reLat, (m, g1) => g1) });
        } else if (TIME_SERIES_TIME_FIELD_NAME === field.name) {
          idxs_time[s] = f;
        } else if (reBoundaries.test(field.name)) {
          idxs_boundaries.push({ s, f });
        }
      }
    }

    for (const idx of idxs_lon) {
      const vals = data.series[idx.s].fields[idx.f].values;
      const time = data.series[idx.s].fields[idxs_time[idx.s]].values;
      for (let i = 0; i < vals.length; i++) {
        const val = vals.get(i);
        if (val === null || val === undefined) {
          continue;
        }
        addLongitude(boats, idx.src ?? 'UNK', time.get(i), val);
      }
    }

    for (const idx of idxs_lat) {
      const vals = data.series[idx.s].fields[idx.f].values;
      const time = data.series[idx.s].fields[idxs_time[idx.s]].values;
      for (let i = 0; i < vals.length; i++) {
        const val = vals.get(i);
        if (val === null || val === undefined) {
          continue;
        }
        addLatitude(boats, idx.src ?? 'UNK', time.get(i), val);
      }
    }

    for (const idx of idxs_boundaries) {
      const vals = data.series[idx.s].fields[idx.f].values;
      const time = data.series[idx.s].fields[idxs_time[idx.s]].values;
      for (let i = 0; i < vals.length; i++) {
        const val = vals.get(i);
        if (val === null || val === undefined) {
          continue;
        }
        boundaries.push({ t: time.get(i), data: val });
      }
    }

    // TEST/DEBUG CODE
    //Add 5 'samples' from two possible values. When processing, just the last one(s) will be used
    // if (boundaries.length == 0) {
    //   for (let count = 0; count < 5; count++) {
    //     let sampleGeoJson = samples[Math.floor(Math.random() * 2)];
    //     boundaries.push({ t: Date.now(), data: sampleGeoJson });
    //   }
    // }
    // END TEST/DEBUG CODE

    //Update all the map datasets with the current boat information
    updatePoints(boats, boundaries);
  }

  const zoomToData = () => {
    if (displayedData.data) {
      // @ts-ignore: Object is possibly 'null'.
      zoomToDataset(map.current, displayedData);
    }
  };

  const styles = getStyles();

  let infoBar = <div></div>;

  if (options.showInfoBar) {
    infoBar = (
      <div className="sidebar">
        Longitude: {lng} | Latitude: {lat} | Zoom: {zoom} |{' '}
        <button className="zoom-to-data" onClick={zoomToData}>
          Zoom to data
        </button>
      </div>
    );
  }

  return (
    <div>
      {infoBar}
      <div
        id="oracle-maps-container"
        ref={mapContainer}
        className={cx(
          styles.wrapper,
          css`
            width: ${width}px;
            height: ${height}px;
          `
        )}
      />
    </div>
  );
};

const getStyles = stylesFactory(() => {
  return {
    wrapper: css`
      position: relative;
    `,
    svg: css`
      position: absolute;
      top: 0;
      left: 0;
    `,
    textBox: css`
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
    `,
  };
});
