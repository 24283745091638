import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { SimplePanel } from './SimplePanel';

export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel).setPanelOptions((builder) => {
  return builder
    .addSelect({
      path: 'mapStyle',
      name: 'Map Style',
      description: 'Background map style',
      defaultValue: 'osm-bright',
      settings: {
        options: [
          {
            value: 'osm-bright',
            label: 'OSM Bright',
          },
          {
            value: 'osm-dark-matter',
            label: 'OSM Dark Matter',
          },
        ],
      },
    })
    .addBooleanSwitch({
      path: 'showInfoBar',
      name: 'Show Info Bar',
      defaultValue: true,
    })
    .addTextInput({
      path: 'idName',
      name: 'ID label',
      description: 'Label name for source ID',
      defaultValue: 'source',
    })
    .addTextInput({
      path: 'latitudeName',
      name: 'Latitude column',
      description: 'Latitude column name in datasource',
      defaultValue: 'LATITUDE_GPS_unk',
    })
    .addTextInput({
      path: 'longitudeName',
      name: 'Longitude column',
      description: 'Longitude column name in datasource',
      defaultValue: 'LONGITUDE_GPS_unk',
    })
    .addTextInput({
      path: 'boundariesName',
      name: 'Boundaries column',
      description: 'Boundaries column name in datasource. Buoys and fleet boats are also included here.',
      defaultValue: 'BOUNDARIES',
    })
    .addNumberInput({
      path: 'multiplier',
      name: 'Value multiplier',
      description: 'When lon/lat data needs to be multiplied (or divided) by a number. Use 1 to leave data as-is.',
      defaultValue: 1,
    })
    .addRadio({
      path: 'iconStyle',
      name: 'Icon style',
      defaultValue: 'rocket_11',
      settings: {
        options: [
          {
            value: 'rocket_11',
            label: 'Rocket',
          },
          // {
          //   value: 'rocket_white',
          //   label: 'White Rocket'
          // },
          {
            value: 'circle_stroked_11',
            label: 'Circle',
          },
          {
            value: 'swimming_11',
            label: 'Swimmer',
          },
        ],
      },
    })
    .addRadio({
      path: 'trailSize',
      name: 'Trail Size',
      defaultValue: 50,
      settings: {
        options: [
          {
            value: 20,
            label: '20',
          },
          {
            value: 50,
            label: '50',
          },
          {
            value: 100,
            label: '100',
          },
          {
            value: 1000,
            label: '1000',
          },
        ],
      },
    });
  // .addTextInput({
  //   path: 'text',
  //   name: 'Simple text option',
  //   description: 'Description of panel option',
  //   defaultValue: 'Default value of text input option',
  // })
  // .addBooleanSwitch({
  //   path: 'showSeriesCount',
  //   name: 'Show series counter',
  //   defaultValue: false,
  // })
  // .addRadio({
  //   path: 'seriesCountSize',
  //   defaultValue: 'sm',
  //   name: 'Series counter size',
  //   settings: {
  //     options: [
  //       {
  //         value: 'sm',
  //         label: 'Small',
  //       },
  //       {
  //         value: 'md',
  //         label: 'Medium',
  //       },
  //       {
  //         value: 'lg',
  //         label: 'Large',
  //       },
  //     ],
  //   },
  //   showIf: config => config.showSeriesCount,
  // })
  // .addRadio({
  //   path: 'color',
  //   name: 'Circle color',
  //   defaultValue: 'red',
  //   settings: {
  //     options: [
  //       {
  //         value: 'red',
  //         label: 'Red'
  //       },
  //       {
  //         value: 'green',
  //         label: 'Green'
  //       },
  //       {
  //         value: 'blue',
  //         label: 'Blue'
  //       }
  //     ]
  //   }
  // });
});
